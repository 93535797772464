import lodash from 'lodash'
import { createSelector } from 'reselect'
import _ from 'underscore'

import {
  STUDENT_FEES_DATA,
  START_POST_STUDENT_FEES,
  STUDENT_FEES_POST_FAILURE,
  APP_LOGOUT,
  UPDATE_STUDENTFEE_DATA,
  FEE_RECEIPT_DATA,
  // fee receipt actions
  START_FETCH_STUDENT_FEE_RECEIPT,
  FEE_RECEIPT_ARRAY,
  FEE_RECEIPT_FETCH_FAIL,
  APP_POST_ORDER_START,
  APP_POST_ORDER,
  APP_POST_ORDER_FAIL,
  APP_CONFIRM_ORDER_START,
  APP_CONFIRM_ORDER,
  APP_CONFIRM_ORDER_FAIL,
  APP_POST_REFUND_RECEIPT_START,
  APP_POST_REFUND_RECEIPT,
  APP_POST_REFUND_RECEIPT_FAIL,
  POST_FEE_DUE_REMINDER_START,
  POST_FEE_DUE_REMINDER_SUCCESS,
  POST_FEE_DUE_REMINDER_FAIL,
  WAIVE_STUDENT_FEES_START,
  WAIVE_STUDENT_FEES,
  WAIVE_STUDENT_FEES_FAIL,
  FEE_RECEIPT_DATA_DELETE,
  START_DELETE_STUDENT_FEES,
  GET_WAIVE_STUDENT_FEES_FAIL,
  GET_WAIVE_STUDENT_FEES_START,
  GET_WAIVE_STUDENT_FEES,
  POST_TRANSPORT_FEE_START,
  POST_TRANSPORT_FEE_SUCCESS,
  POST_TRANSPORT_FEE_FAIL,
  GET_STUDENT_TRANSPORT_FEE_START,
  GET_STUDENT_TRANSPORT_FEE_SUCCESS,
  GET_STUDENT_TRANSPORT_FEE_FAIL,
  PATCH_TRANSPORT_FEE_START,
  PATCH_TRANSPORT_FEE_SUCCESS,
  PATCH_TRANSPORT_FEE_FAIL,
  GET_FEES_CLASS_WISE_START,
  GET_FEES_CLASS_WISE_SUCCESS,
  GET_FEES_CLASS_WISE_FAIL,
  GET_DELETED_RECEIPTS_START,
  GET_DELETED_RECEIPTS_SUCCESS,
  GET_DELETED_RECEIPTS_FAIL,
  DELETE_STUDENT_FEES_START,
  DELETE_STUDENT_FEES_SUCCESS,
  DELETE_STUDENT_FEES_FAIL,
  POST_STUDENT_FINE_FEES_START,
  POST_STUDENT_FINE_FEES_SUCCESS,
  POST_STUDENT_FINE_FEES_FAIL,
  RESET_PROCESSING_FEE_START,
  RESET_PROCESSING_FEE_SUCCESS,
  RESET_PROCESSING_FEE_FAIL,
  ADD_FEE_AND_GENERATE_RECEIPT_START,
  ADD_FEE_AND_GENERATE_RECEIPT_FAIL,
  PATCH_FEE_DONTPAYONLINE_START,
  PATCH_FEE_DONTPAYONLINE_SUCCESS,
  PATCH_FEE_DONTPAYONLINE_FAIL,
  GET_FEE_REPORT_ALL_START,
  GET_FEE_REPORT_ALL_SUCCESS,
  GET_FEE_REPORT_ALL_FAILED,
  ACKNOWLEDGEMENT_RECEIPT_START,
  ACKNOWLEDGEMENT_RECEIPT_SUCCESS,
  ACKNOWLEDGEMENT_RECEIPT_FAIL,
  MARK_CHEQUE_PAID_START,
  MARK_CHEQUE_PAID_SUCCESS,
  MARK_CHEQUE_PAID_FAIL
} from '../helpers/actions'

import { getActiveStudentFee } from './ui/studentFees'
import { getActiveStudent } from './ui/studentProfile'

const initialState = {
  isLoading: false,
  isLoadingDelete: false,
  isDeletingSuccess: false,
  byId: {},
  allIds: [],
  postInProgress: false,
  postStatus: '',
  receiptStatus: '',
  feeReceipt: {},
  feeReceiptArr: [],
  err: '',
  order: '',
  isLoadingOrder: false,
  getOrder: '',
  isLoadingConfirmOrder: false,
  confirmOrder: '',
  isLoadingRefundReceipt: false,
  postRefundReceiptStatus: '',
  refundedReceipts: [],
  isLoadingReminder: false,
  reminderError: false,
  postReminderStatus: '',
  isLoadingWaiveFees: false,
  postWaiveFees: '',
  waivedFees: {},
  isLoadingStudentWaiveFees: false,
  assignTransportLoading: false,
  isLoadingTransportFee: false,
  transportFee: [],
  patchTransportLoading: false,
  classWiseFee: [],
  deletedReceipts: [],
  isDeletedReceiptsLoading: false,
  deletedFeeLoading: false,
  fineData: [],
  fineError: false,
  resetProcessingLoading: false,
  resetProcessingErr: false,
  addAndGenerateReceiptMsg: '',
  dontPayOnlineLoading: false,
  dontPayOnlineErr: false,
  studentFeesCsvLoading: false,
  studentFeesCsvErr: false,
  studentFeeCsvData: [],
  studentFeeStatusCsvData: [],
  acknowledgementLoading: false,
  chequeMarkedLoading: false
}

export const studentFees = (state = initialState, action) => {
  switch (action.type) {
    case STUDENT_FEES_DATA: {
      return Object.assign({}, state, {
        byId: action.byId,
        allIds: action.allIds,
        postInProgress: false,
        postStatus: 'success'
      })
    }
    case FEE_RECEIPT_DATA_DELETE:
      return Object.assign({}, state, {
        isLoadingDelete: false,
        isDeletingSuccess: true
      })
    case START_DELETE_STUDENT_FEES:
      return Object.assign({}, state, {
        isLoadingDelete: true,
        isDeletingSuccess: false
      })
    case START_POST_STUDENT_FEES:
      return Object.assign({}, state, {
        postInProgress: true,
        postStatus: '',
        receiptStatus: '',
        addAndGenerateReceiptMsg: ''
      })
    case STUDENT_FEES_POST_FAILURE:
      return Object.assign({}, state, {
        postInProgress: false,
        postStatus: 'fail',
        receiptStatus: 'fail',
        addAndGenerateReceiptMsg: 'fail'
      })
    case UPDATE_STUDENTFEE_DATA: {
      const byId = state.byId
      let allIds = lodash.uniq(state.allIds.concat(action.allIds))
      return Object.assign({}, state, {
        postInProgress: false,
        postStatus: 'success',
        byId: Object.assign({}, byId, action.byId),
        allIds,
        addAndGenerateReceiptMsg: 'success'
      })
    }
    case FEE_RECEIPT_DATA:
      return Object.assign({}, state, {
        postInProgress: false,
        receiptStatus: 'success',
        feeReceipt: action.receipt,
        addAndGenerateReceiptMsg: 'success'
      })
    case START_FETCH_STUDENT_FEE_RECEIPT:
      return Object.assign({}, state, {
        isLoading: true,
        err: ''
      })
    case FEE_RECEIPT_ARRAY:
      return Object.assign({}, state, {
        isLoading: false,
        feeReceiptArr: action.data
      })
    case FEE_RECEIPT_FETCH_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        err: action.err
      })
    case APP_POST_ORDER: {
      return Object.assign({}, state, {
        order: action.data,
        isLoadingOrder: false,
        getOrder: 'success'
      })
    }
    case APP_POST_ORDER_START:
      return Object.assign({}, state, {
        isLoadingOrder: true,
        getOrder: ''
      })
    case APP_POST_ORDER_FAIL:
      return Object.assign({}, state, {
        isLoadingOrder: false,
        getOrder: 'fail'
      })
    case APP_CONFIRM_ORDER: {
      return Object.assign({}, state, {
        isLoadingConfirmOrder: false,
        confirmOrder: 'success'
      })
    }
    case APP_CONFIRM_ORDER_START:
      return Object.assign({}, state, {
        isLoadingConfirmOrder: true,
        confirmOrder: ''
      })
    case APP_CONFIRM_ORDER_FAIL:
      return Object.assign({}, state, {
        isLoadingConfirmOrder: false,
        confirmOrder: 'fail'
      })
    case APP_POST_REFUND_RECEIPT: {
      return Object.assign({}, state, {
        isLoadingRefundReceipt: false,
        postRefundReceiptStatus: 'success',
        refundedReceipts: action.data
      })
    }
    case APP_POST_REFUND_RECEIPT_START:
      return Object.assign({}, state, {
        isLoadingRefundReceipt: true,
        postRefundReceiptStatus: ''
      })
    case APP_POST_REFUND_RECEIPT_FAIL:
      return Object.assign({}, state, {
        isLoadingRefundReceipt: false,
        postRefundReceiptStatus: 'fail'
      })
    case POST_FEE_DUE_REMINDER_START:
      return Object.assign({}, state, {
        isLoadingReminder: true,
        reminderError: false,
        postReminderStatus: ''
      })
    case POST_FEE_DUE_REMINDER_SUCCESS:
      return Object.assign({}, state, {
        isLoadingReminder: false,
        reminderError: false,
        postReminderStatus: 'reminderPosted'
      })
    case POST_FEE_DUE_REMINDER_FAIL:
      return Object.assign({}, state, {
        isLoadingReminder: false,
        reminderError: true,
        postReminderStatus: ''
      })
    case WAIVE_STUDENT_FEES: {
      return Object.assign({}, state, {
        isLoadingWaiveFees: false,
        postWaiveFees: 'success',
        waivedFees: action.data
      })
    }
    case WAIVE_STUDENT_FEES_START:
      return Object.assign({}, state, {
        isLoadingWaiveFees: true,
        postWaiveFees: ''
      })
    case WAIVE_STUDENT_FEES_FAIL:
      return Object.assign({}, state, {
        isLoadingWaiveFees: false,
        postWaiveFees: 'fail'
      })
    case GET_WAIVE_STUDENT_FEES: {
      return Object.assign({}, state, {
        isLoadingStudentWaiveFees: false,
        waivedFees: action.data
      })
    }
    case GET_WAIVE_STUDENT_FEES_START:
      return Object.assign({}, state, {
        isLoadingStudentWaiveFees: true,
        postWaiveFees: ''
      })
    case GET_WAIVE_STUDENT_FEES_FAIL:
      return Object.assign({}, state, {
        isLoadingStudentWaiveFees: false,
        postWaiveFees: 'fail'
      })
    case POST_TRANSPORT_FEE_START:
      return Object.assign({}, state, {
        assignTransportLoading: true,
        postStatus: ''
      })
    case POST_TRANSPORT_FEE_SUCCESS:
      return Object.assign({}, state, {
        assignTransportLoading: false,
        postStatus: 'success'
      })
    case POST_TRANSPORT_FEE_FAIL: {
      return Object.assign({}, state, {
        assignTransportLoading: false,
        postStatus: 'fail'
      })
    }
    case GET_STUDENT_TRANSPORT_FEE_START:
      return Object.assign({}, state, {
        isLoadingTransportFee: true,
        postStatus: ''
      })
    case GET_STUDENT_TRANSPORT_FEE_SUCCESS:
      return Object.assign({}, state, {
        isLoadingTransportFee: false,
        transportFee: action.data.fees,
        postStatus: 'success'
      })
    case GET_STUDENT_TRANSPORT_FEE_FAIL:
      return Object.assign({}, state, {
        isLoadingTransportFee: false,
        postStatus: 'fail'
      })
    case PATCH_TRANSPORT_FEE_START:
      return Object.assign({}, state, {
        patchTransportLoading: true,
        postStatus: ''
      })
    case PATCH_TRANSPORT_FEE_SUCCESS:
      return Object.assign({}, state, {
        patchTransportLoading: false,
        postStatus: 'success'
      })
    case PATCH_TRANSPORT_FEE_FAIL:
      return Object.assign({}, state, {
        patchTransportLoading: false,
        postStatus: 'fail'
      })
    case GET_FEES_CLASS_WISE_START:
      return Object.assign({}, state, {
        isLoading: true,
        classWiseFee: []
      })
    case GET_FEES_CLASS_WISE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        classWiseFee: action.data
      })
    case GET_FEES_CLASS_WISE_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        classWiseFee: []
      })
    case GET_DELETED_RECEIPTS_START:
      return Object.assign({}, state, {
        isDeletedReceiptsLoading: true,
        deletedReceipts: []
      })
    case GET_DELETED_RECEIPTS_SUCCESS:
      return Object.assign({}, state, {
        isDeletedReceiptsLoading: false,
        deletedReceipts: action.data
      })
    case GET_DELETED_RECEIPTS_FAIL:
      return Object.assign({}, state, {
        isDeletedReceiptsLoading: false,
        deletedReceipts: []
      })
    case DELETE_STUDENT_FEES_START:
      return Object.assign({}, state, {
        deletedFeeLoading: true
      })
    case DELETE_STUDENT_FEES_SUCCESS:
      return Object.assign({}, state, {
        deletedFeeLoading: false
      })
    case DELETE_STUDENT_FEES_FAIL:
      return Object.assign({}, state, {
        deletedFeeLoading: false
      })
    case POST_STUDENT_FINE_FEES_START:
      return Object.assign({}, state, {
        addFineLoading: true,
        fineError: false
      })
    case POST_STUDENT_FINE_FEES_SUCCESS:
      return Object.assign({}, state, {
        addFineLoading: false,
        fineError: false,
        fineData: action.data
      })
    // const byId = state.byId
    // let allIds = lodash.uniq(state.allIds.concat(action.allIds))
    // return Object.assign({}, state, {
    //   postInProgress: false,
    //   postStatus: 'success',
    //   byId: Object.assign({}, byId, action.byId),
    //   allIds
    // })
    case POST_STUDENT_FINE_FEES_FAIL:
      return Object.assign({}, state, {
        addFineLoading: false,
        fineError: true
      })
    case RESET_PROCESSING_FEE_START:
      return Object.assign({}, state, {
        resetProcessingLoading: true,
        resetProcessingErr: false
      })
    case RESET_PROCESSING_FEE_SUCCESS:
      return Object.assign({}, state, {
        resetProcessingLoading: false,
        resetProcessingErr: false
      })
    case RESET_PROCESSING_FEE_FAIL:
      return Object.assign({}, state, {
        resetProcessingLoading: false,
        resetProcessingErr: true
      })
    case PATCH_FEE_DONTPAYONLINE_START:
      return Object.assign({}, state, {
        dontPayOnlineLoading: true,
        dontPayOnlineErr: false
      })
    case PATCH_FEE_DONTPAYONLINE_SUCCESS:
      return Object.assign({}, state, {
        dontPayOnlineLoading: false,
        dontPayOnlineErr: false
      })
    case PATCH_FEE_DONTPAYONLINE_FAIL:
      return Object.assign({}, state, {
        dontPayOnlineLoading: false,
        dontPayOnlineErr: true
      })
    case GET_FEE_REPORT_ALL_START:
      return Object.assign({}, state, {
        studentFeesCsvLoading: true,
        studentFeesCsvErr: false
      })
    case GET_FEE_REPORT_ALL_SUCCESS:
      return Object.assign({}, state, {
        studentFeesCsvLoading: false,
        studentFeesCsvErr: false,
        studentFeeCsvData: action.data?.student_fee_csv_data,
        studentFeeStatusCsvData: action.data?.student_fee_status_csv_data
      })
    case GET_FEE_REPORT_ALL_FAILED:
      return Object.assign({}, state, {
        studentFeesCsvLoading: false,
        studentFeesCsvErr: true
      })
    case ACKNOWLEDGEMENT_RECEIPT_START:
      return Object.assign({}, state, {
        acknowledgementLoading: true,
        receiptStatus: ''
      })
    case ACKNOWLEDGEMENT_RECEIPT_SUCCESS:
      return Object.assign({}, state, {
        acknowledgementLoading: false,
        feeReceipt: action.receipt,
        receiptStatus: 'success'
      })
    case ACKNOWLEDGEMENT_RECEIPT_FAIL:
      return Object.assign({}, state, {
        acknowledgementLoading: false,
        receiptStatus: ''
      })
    case MARK_CHEQUE_PAID_START:
      return Object.assign({}, state, {
        chequeMarkedLoading: true,
        receiptStatus: ''
      })
    case MARK_CHEQUE_PAID_SUCCESS:
      return Object.assign({}, state, {
        chequeMarkedLoading: false,
        receiptStatus: 'success'
      })
    case MARK_CHEQUE_PAID_FAIL:
      return Object.assign({}, state, {
        chequeMarkedLoading: false,
        receiptStatus: ''
      })
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

export const isLoading = state => state.studentFees.isLoading
export const getById = state => state.studentFees.byId
export const getisLoadingDelete = state => state.studentFees.isLoadingDelete
export const getisDeletingSuccess = state => state.studentFees.isDeletingSuccess
export const getAllIds = state => state.studentFees.allIds
export const getPostInProgress = state => state.studentFees.postInProgress
export const getPostStatus = state => state.studentFees.postStatus
export const getFeeReceipt = state => state.studentFees.feeReceipt
export const getFeeReceiptArr = state => state.studentFees.feeReceiptArr

export const getOrder = state => state.studentFees.order
export const getisLoadingOrder = state => state.studentFees.isLoadingOrder
export const getOrderStatus = state => state.studentFees.getOrder
export const getisLoadingConfirmOrder = state =>
  state.studentFees.isLoadingConfirmOrder
export const getConfirmOrder = state => state.studentFees.confirmOrder
export const getIsLoadingRefundReceipt = state =>
  state.studentFees.isLoadingRefundReceipt
export const getPostRefundReceiptStatus = state =>
  state.studentFees.postRefundReceiptStatus
export const getRefundedReceipts = state => state.studentFees.refundedReceipts
export const getIsLoadingReminder = state => state.studentFees.isLoadingReminder
export const getReminderError = state => state.studentFees.reminderError
export const getPostReminderStatus = state =>
  state.studentFees.postReminderStatus
export const getisLoadingWaiveFees = state =>
  state.studentFees.isLoadingWaiveFees
export const getpostWaiveFees = state => state.studentFees.postWaiveFees
export const getwaivedFees = state => state.studentFees.waivedFees
export const getIsLoadingStudentWaiveFees = state =>
  state.studentFees.isLoadingStudentWaiveFees
export const getAssignTransportLoading = state =>
  state.studentFees.assignTransportLoading
export const getTransportFeeLoading = state =>
  state.studentFees.isLoadingTransportFee
export const getTransportFee = state => state.studentFees.transportFee
export const getPatchTransportLoading = state =>
  state.studentFees.patchTransportLoading
export const getClassWiseFee = state => state.studentFees.classWiseFee
export const getDeletedReceipts = state => state.studentFees.deletedReceipts
export const getDeletedReceiptsLoading = state =>
  state.studentFees.isDeletedReceiptsLoading
export const getDeleteFeeLoading = state => state.studentFees.deletedFeeLoading
export const getReceiptStatus = state => state.studentFees.receiptStatus
export const getFineData = state => state.studentFees.fineData
export const getFineError = state => state.studentFees.fineError
export const getAddFineLoading = state => state.studentFees.addFineLoading
export const getDontPayOnlineErr = state => state.studentFees.dontPayOnlineErr
export const getDontPayOnlineLoading = state =>
  state.studentFees.dontPayOnlineLoading
export const getResetProcessingLoading = state =>
  state.studentFees.resetProcessingLoading
export const getResetProcessingError = state =>
  state.studentFees.resetProcessingErr
export const getAddAndGenerateReceiptMsg = state =>
  state.studentFees.addAndGenerateReceiptMsg
export const getAcknowledgementLoading = state =>
  state.studentFees.acknowledgementLoading
export const getChequeMarkedLoading = state =>
  state.studentFees.chequeMarkedLoading

export const getActiveStudentFeeObject = createSelector(
  getById,
  getActiveStudentFee,
  (studentFee, activeFee) => studentFee[activeFee]
)

export const getActiveStudentUnpaidFee = createSelector(
  getById,
  getAllIds,
  getActiveStudent,
  (studentFee, allIds, student) => {
    return allIds
      .filter(id => studentFee[id]['studentId'] === student)
      .filter(id => studentFee[id]['payment']['paid'] === false)
      .map(id => studentFee[id])
  }
)

export const getStudentsAllFee = createSelector(
  getById,
  getAllIds,
  (studentFee, allIds) => {
    return allIds.map(id => studentFee[id])
  }
)

export const getActiveStudentPaidFee = createSelector(
  getById,
  getAllIds,
  getActiveStudent,
  (studentFee, allIds, student) => {
    return allIds
      .filter(id => studentFee[id]['studentId'] === student)
      .filter(id => studentFee[id]['payment']['paid'] === true)
      .map(id => studentFee[id])
  }
)

export const getStudentFessByStudentId = createSelector(
  getById,
  getAllIds,
  (studentFee, allIds) => {
    return _.groupBy(
      allIds.map(item => studentFee[item]),
      x => x.studentId
    )
  }
)

export const getFeeReceiptByStudent = createSelector(
  getFeeReceiptArr,
  studentReceiptArr => {
    if (studentReceiptArr.length === 0) return {}
    const obj = {}
    studentReceiptArr.forEach(receiptObj => {
      obj[receiptObj.studentId] = receiptObj
    })
    return obj
  }
)

export const getStudentFeesCsvLoading = state =>
  state.studentFees.studentFeesCsvLoading
export const getStudentFeesCsvErr = state => state.studentFees.studentFeesCsvErr
export const getStudentFeeCsvData = state => state.studentFees.studentFeeCsvData
export const getStudentFeeStatusCsvData = state =>
  state.studentFees.studentFeeStatusCsvData
