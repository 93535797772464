import { error } from 'jquery'
import {
  GET_ALL_TASK_FAIL,
  GET_ALL_TASK_SUCCESS,
  GET_ALL_TASK_START,
  APP_LOGOUT,
  GET_EDIT_TASK_START,
  GET_EDIT_TASK_SUCCESS,
  GET_EDIT_TASK_FAIL,
  GET_TEACHER_TASKS_START,
  GET_TEACHER_TASKS_SUCCESS,
  GET_TEACHER_TASKS_FAIL,
  UPDATE_TEACHER_TASK_START,
  UPDATE_TEACHER_TASK_SUCCESS,
  UPDATE_TEACHER_TASK_FAIL,
  GET_ALL_TEACHER_TASK_START,
  GET_ALL_TEACHER_TASK_SUCCESS,
  GET_ALL_TEACHER_TASK_FAIL,
  POST_TASK_START,
  POST_TASK_SUCCESS,
  POST_TASK_FAIL
} from '../helpers/actions'

const initialState = {
  isTaskListLoading: false,
  isPostTaskLoading: false,
  taskList: [],
  editTask: {},
  editTaskIsLoading: false,
  teacherTaskList: [],
  teacherTaskListIsLoading: false,
  updateTeacherTaskLoading: false,
  teacherAllTask: [],
  teacherAllTaskIsLoading: false,
  error: false
}

export const task = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case GET_ALL_TASK_START:
      return Object.assign({}, state, {
        isTaskListLoading: true,
        error: false
      })
    case GET_ALL_TASK_SUCCESS:
      return Object.assign({}, state, {
        isTaskListLoading: false,
        error: false,
        taskList: action.data
      })
    case GET_ALL_TASK_FAIL:
      return Object.assign({}, state, {
        isTaskListLoading: false,
        error: true
      })
    case GET_EDIT_TASK_START:
      return Object.assign({}, state, {
        editTaskIsLoading: true,
        error: false
      })
    case GET_EDIT_TASK_SUCCESS:
      return Object.assign({}, state, {
        editTaskIsLoading: false,
        error: false,
        editTask: action.data
      })
    case GET_EDIT_TASK_FAIL:
      return Object.assign({}, state, {
        editTaskIsLoading: false,
        error: true
      })
    case GET_TEACHER_TASKS_START:
      return Object.assign({}, state, {
        teacherTaskListIsLoading: true,
        error: false
      })
    case GET_TEACHER_TASKS_SUCCESS:
      return Object.assign({}, state, {
        teacherTaskListIsLoading: false,
        error: false,
        teacherTaskList: action.data
      })
    case GET_TEACHER_TASKS_FAIL:
      return Object.assign({}, state, {
        teacherTaskListIsLoading: false,
        error: true
      })
    case UPDATE_TEACHER_TASK_START:
      return Object.assign({}, state, {
        updateTeacherTaskLoading: true,
        error: false
      })
    case UPDATE_TEACHER_TASK_SUCCESS:
      return Object.assign({}, state, {
        updateTeacherTaskLoading: false,
        error: false
      })
    case UPDATE_TEACHER_TASK_FAIL:
      return Object.assign({}, state, {
        updateTeacherTaskLoading: false,
        error: true
      })
    case GET_ALL_TEACHER_TASK_START:
      return Object.assign({}, state, {
        teacherAllTaskIsLoading: true,
        error: false
      })
    case GET_ALL_TEACHER_TASK_SUCCESS:
      return Object.assign({}, state, {
        teacherAllTaskIsLoading: false,
        error: false,
        teacherAllTask: action.data
      })
    case GET_ALL_TEACHER_TASK_FAIL:
      return Object.assign({}, state, {
        teacherAllTaskIsLoading: false,
        error: true
      })
    case POST_TASK_START:
      return Object.assign({}, state, {
        isPostTaskLoading: true,
        error: false
      })
    case POST_TASK_SUCCESS:
      return Object.assign({}, state, {
        isPostTaskLoading: false,
        error: false
      })
    case POST_TASK_START:
      return Object.assign({}, state, {
        isPostTaskLoading: false,
        error: true
      })
    default:
      return state
  }
}

export const getIsTaskListLoading = state => state.task.isTaskListLoading
export const getIsError = state => state.task.error
export const getTaskList = state => state.task.taskList

export const getEditTask = state => state.task.editTask
export const getEditTaskLoading = state => state.task.editTaskIsLoading

export const getTeacherTaskList = state => state.task.teacherTaskList
export const getTeacherTaskListLoading = state =>
  state.task.teacherTaskListIsLoading

export const getTeacherUpdateIsLoading = state =>
  state.task.updateTeacherTaskLoading

export const getAllTeacherTask = state => state.task.teacherAllTask
export const getAllTeacherTaskLoading = state =>
  state.task.teacherAllTaskIsLoading

export const getPostTaskLoading = state => state.task.isPostTaskLoading
