import { createSelector } from 'reselect'

import {
  APP_LOGOUT,
  ENQUIRY_LIST,
  UPDATE_APPLICATION,
  START_POST_APPLICATION,
  APPLICATION_POST_FAILURE,
  APPLICATION_POST_SUCCESS,
  START_ENQUIRY_LIST,
  FAILED_ENQUIRY_LIST,
  ADMISSION_OVERVIEW_START,
  ADMISSION_OVERVIEW_SUCCESS,
  ADMISSION_OVERVIEW_FAIL
} from '../helpers/actions'
import { getById as studentdsById } from './students'
import { getActiveApplication } from './ui/application'

const initialState = {
  byId: {},
  allIds: [],
  postInProgress: false,
  postStatus: '',
  isLoading: false,
  overviewLoading: false,
  admissionOverview: []
}

export const applications = (state = initialState, action) => {
  switch (action.type) {
    case START_POST_APPLICATION:
      return Object.assign({}, state, {
        postInProgress: true,
        postStatus: ''
      })
    case START_ENQUIRY_LIST:
      return Object.assign({}, state, {
        isLoading: true
      })
    case ENQUIRY_LIST:
      return Object.assign({}, state, {
        byId: action.byId,
        allIds: action.allIds,
        isLoading: false
      })
    case FAILED_ENQUIRY_LIST:
      return Object.assign(
        {},
        {
          isLoading: false
        }
      )
    case APPLICATION_POST_FAILURE:
      return Object.assign({}, state, {
        postInProgress: false,
        postStatus: 'fail'
      })
    case APPLICATION_POST_SUCCESS:
      return Object.assign({}, state, {
        postInProgress: false,
        postStatus: 'success'
      })
    case UPDATE_APPLICATION: {
      const byId = state.byId
      return Object.assign({}, state, {
        byId: {
          ...byId,
          [action.data['_id']]: action.data
        },
        postInProgress: false,
        postStatus: 'success'
      })
    }
    case ADMISSION_OVERVIEW_START:
      return Object.assign({}, state, {
        overviewLoading: true
      })
    case ADMISSION_OVERVIEW_SUCCESS:
      return Object.assign({}, state, {
        admissionOverview: action.data,
        overviewLoading: false
      })
    case ADMISSION_OVERVIEW_FAIL:
      return Object.assign({}, state, {
        overviewLoading: false
      })
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

// selectors
export const getById = state =>
  state.applications.byId ? state.applications.byId : {}
export const getAllIds = state =>
  state.applications.allIds ? state.applications.allIds : []
export const getPostInProgress = state => state.applications.postInProgress
export const getPostStatus = state => state.applications.postStatus
export const isLoading = state => state.applications.isLoading
// state.applications.isLoading ? false : state.applications.isLoading

export const getOverviewLoading = state => state.applications.overviewLoading
export const getAdmissionOverview = state =>
  state.applications.admissionOverview

export const getApplicationList = createSelector(
  getById,
  studentdsById,
  (byId, students) => {
    return Object.keys(byId).map(enquiry => {
      const studentId = byId[enquiry]['studentId']
      const student = students[studentId]
      const student_group_id = byId[enquiry]['studentGroupId']
      let name = ''
      let parentName = ''
      let phone = 0
      let email = ''
      let date_of_birth = 0
      let parents = []
      if (student) {
        name = student['name']
        parentName = student['parents'][0]
          ? student['parents'][0]['name']
          : null
        phone = student['phone'][0] ? student['phone'][0]['number'] : null
        email = student['parents'][0] ? student['parents'][0]['email'] : null
        date_of_birth = student['date_of_birth']
        parents = student['parents']
      }
      return {
        ...byId?.[enquiry],
        studentId,
        name,
        parentName,
        phone,
        email,
        date_of_birth,
        student_group_id,
        parents
      }
    })
  }
)

export const getActiveApplicationObject = createSelector(
  getApplicationList,
  getActiveApplication,
  (applications, activeApplication) => {
    const application = applications.filter(
      obj => obj['_id'] === activeApplication
    )[0]
    if (application) return application
    return {
      _id: '',
      tempId: '',
      statusChangeDates: [],
      dateOfEnquiry: '',
      courseApplied: '',
      status: '',
      studentId: '',
      name: '',
      parentName: '',
      phone: 0,
      email: '',
      date_of_birth: 0
    }
  }
)
