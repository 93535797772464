import {
  GET_DEPARTMENT_LIST_SUCCESS,
  GET_DEPARTMENT_LIST_START,
  GET_DEPARTMENT_LIST_FAIL,
  GET_DEPARTMENT_START,
  GET_DEPARTMENT_SUCCESS,
  GET_DEPARTMENT_FAIL,
  GET_DEPARTMENT_LEAVES_START,
  GET_DEPARTMENT_LEAVES_SUCCESS,
  GET_DEPARTMENT_LEAVES_FAIL,
  APP_LOGOUT
} from '../../helpers/actions'

const initialState = {
  isLoading: false,
  departmentList: [],
  department: {},
  departmentLeavesIsLoading: false,
  departmentLeaves: [],
  error: false
}

export const department = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case GET_DEPARTMENT_LIST_START:
      return Object.assign({}, state, {
        isLoading: true,
        error: false
      })
    case GET_DEPARTMENT_LIST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: false,
        departmentList: action.data
      })
    case GET_DEPARTMENT_LIST_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        error: true
      })
    case GET_DEPARTMENT_START:
      return Object.assign({}, state, {
        isLoading: true,
        error: false
      })
    case GET_DEPARTMENT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: false,
        department: action.data
      })
    case GET_DEPARTMENT_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        error: true
      })
    case GET_DEPARTMENT_LEAVES_START:
      return Object.assign({}, state, {
        departmentLeavesIsLoading: true,
        error: false
      })
    case GET_DEPARTMENT_LEAVES_SUCCESS:
      return Object.assign({}, state, {
        departmentLeavesIsLoading: false,
        error: false,
        departmentLeaves: action.data
      })
    case GET_DEPARTMENT_LEAVES_FAIL:
      return Object.assign({}, state, {
        departmentLeavesIsLoading: false,
        error: true
      })
    default:
      return state
  }
}

export const getIsLoading = state => state.department.isLoading
export const getIsError = state => state.department.error
export const getDepartmentList = state => state.department.departmentList
export const getDepartment = state => state.department.department
export const getDepartmentLeaves = state => state.department.departmentLeaves
export const getDepartmentLeavesIsLoading = state =>
  state.department.departmentLeavesIsLoading
