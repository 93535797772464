import _ from 'underscore'
import {
  INSTITUION_FEES_DATA,
  APP_LOGOUT,
  FEES_RECEIPT_REPORT_START,
  FEES_RECEIPT_REPORT,
  FEES_RECEIPT_REPORT_FAIL,
  APP_GET_FEE_ORDER_START,
  APP_GET_FEE_ORDER,
  APP_GET_FEE_ORDER_FAIL,
  APP_GET_ALL_CLASS_FEE_REPORT_START,
  APP_GET_ALL_CLASS_FEE_REPORT,
  APP_GET_ALL_CLASS_FEE_REPORT_FAIL,
  APP_POST_FEE_CONCESSION_START,
  APP_POST_FEE_CONCESSION,
  APP_POST_FEE_CONCESSION_FAIL,
  POST_FEE_STRUCTURE_START,
  POST_FEE_STRUCTURE,
  POST_FEE_STRUCTURE_FAIL,
  POST_FEE_RECEIPT_NUMBER_START,
  POST_FEE_RECEIPT_NUMBER,
  POST_FEE_RECEIPT_NUMBER_FAIL,
  GET_FEE_CONCESSION_BY_STUDENT_ID_START,
  GET_FEE_CONCESSION_BY_STUDENT_ID,
  GET_FEE_CONCESSION_BY_STUDENT_ID_FAIL,
  GET_ALL_STUDENTS_FEE_CONCESSION_START,
  GET_ALL_STUDENTS_FEE_CONCESSION,
  GET_ALL_STUDENTS_FEE_CONCESSION_FAIL,
  PATCH_FEE_STRUCTURE,
  PATCH_FEE_STRUCTURE_START,
  PATCH_FEE_STRUCTURE_FAIL,
  GET_FEE_ARREARS_START,
  GET_FEE_ARREARS,
  GET_FEE_ARREARS_FAIL,
  GET_STUDENT_WAIVE_START,
  GET_STUDENT_WAIVE_SUCCESS,
  GET_STUDENT_WAIVE_FAIL,
  UPDATE_BULK_FEE_RECEIPT_START,
  UPDATE_BULK_FEE_RECEIPT_SUCCESS,
  UPDATE_BULK_FEE_RECEIPT_FAIL,
  CHANGE_CONCESSION_STATUS_START,
  CHANGE_CONCESSION_STATUS_SUCCESS,
  CHANGE_CONCESSION_STATUS_FAIL,
  GET_FEE_RECEIPT_NUMBER_TYPE_START,
  GET_FEE_RECEIPT_NUMBER_TYPE_SUCCESS,
  GET_FEE_RECEIPT_NUMBER_TYPE_FAIL,
  GET_DELETED_FEES_START,
  GET_DELETED_FEES_SUCCESS,
  GET_DELETED_FEES_FAILED,
  GET_MONTHWISE_FEE_REPORT_START,
  GET_MONTHWISE_FEE_REPORT,
  GET_MONTHWISE_FEE_REPORT_FAILED
} from '../helpers/actions'
import { createSelector } from 'reselect'

const initialState = {
  byId: {},
  allIds: [],
  feeReceiptReports: [],
  isLoadingFeeReceiptReports: false,
  error: false,
  isLoadingGetFeeOrder: false,
  getFeeOrder: '',
  feeOrders: [],
  isLoadingGetAllClassFeeReport: false,
  allClassFeeReportStatus: '',
  allClassFeeReport: [],
  isLoadingPostFeeConcession: false,
  postFeeConcessionStatus: '',
  feeConcessions: [],
  isLoadingPost: false,
  isConcessionByStudentIdLoading: false,
  isPatchLoading: false,
  feeArrears: {},
  isFeeArrearsLoading: false,
  isLoadingPostReceiptNumber: false,
  feeWaive: [],
  isLoadingFeeWaive: false,
  isBulkFeeReceiptLoading: false,
  concessionStatusLoading: false,
  isReceiptNumberLoading: false,
  feeReceiptNumberType: [],
  deletedFeesLoading: false,
  deletedFees: [],
  isMonthWiseLoading: false,
  isMonthWiseError: false,
  monthWiseReport: {},
  isConcessionByAllStudentsLoading: false,
  postAllStudentFeeConcessionStatus: '',
  allStudentFeeConcessions: []
}

export const fees = (state = initialState, action) => {
  switch (action.type) {
    case INSTITUION_FEES_DATA:
      return Object.assign({}, state, {
        byId: action.byId,
        allIds: action.allIds
      })
    case FEES_RECEIPT_REPORT_START:
      return Object.assign({}, state, {
        isLoadingFeeReceiptReports: true
      })
    case FEES_RECEIPT_REPORT:
      return Object.assign({}, state, {
        feeReceiptReports: action.data,
        isLoadingFeeReceiptReports: false,
        error: false
      })
    case FEES_RECEIPT_REPORT_FAIL:
      return Object.assign({}, state, {
        isLoadingFeeReceiptReports: false,
        error: true
      })
    case APP_GET_FEE_ORDER: {
      return Object.assign({}, state, {
        isLoadingGetFeeOrder: false,
        getFeeOrder: 'success',
        feeOrders: action.data
      })
    }
    case APP_GET_FEE_ORDER_START:
      return Object.assign({}, state, {
        isLoadingGetFeeOrder: true,
        getFeeOrder: '',
        feeOrders: []
      })
    case APP_GET_FEE_ORDER_FAIL:
      return Object.assign({}, state, {
        isLoadingGetFeeOrder: false,
        getFeeOrder: 'fail'
      })
    case APP_GET_ALL_CLASS_FEE_REPORT: {
      return Object.assign({}, state, {
        isLoadingGetAllClassFeeReport: false,
        allClassFeeReportStatus: 'success',
        allClassFeeReport: action.data
      })
    }
    case APP_GET_ALL_CLASS_FEE_REPORT_START:
      return Object.assign({}, state, {
        isLoadingGetAllClassFeeReport: true,
        allClassFeeReportStatus: '',
        allClassFeeReport: []
      })
    case APP_GET_ALL_CLASS_FEE_REPORT_FAIL:
      return Object.assign({}, state, {
        isLoadingGetAllClassFeeReport: false,
        allClassFeeReportStatus: 'fail'
      })
    case APP_POST_FEE_CONCESSION: {
      return Object.assign({}, state, {
        isLoadingPostFeeConcession: false,
        postFeeConcessionStatus: 'success',
        feeConcessions: action.data ? action.data : []
      })
    }
    case APP_POST_FEE_CONCESSION_START:
      return Object.assign({}, state, {
        isLoadingPostFeeConcession: true,
        postFeeConcessionStatus: ''
      })
    case APP_POST_FEE_CONCESSION_FAIL:
      return Object.assign({}, state, {
        isLoadingPostFeeConcession: false,
        postFeeConcessionStatus: 'fail'
      })
    case POST_FEE_STRUCTURE_START:
      return Object.assign({}, state, {
        isLoadingPost: true,
        error: false
      })
    case POST_FEE_STRUCTURE:
      return Object.assign({}, state, {
        isLoadingPost: false,
        error: false
      })
    case POST_FEE_STRUCTURE_FAIL:
      return Object.assign({}, state, {
        isLoadingPost: false,
        error: true
      })
    case GET_FEE_CONCESSION_BY_STUDENT_ID: {
      return Object.assign({}, state, {
        isConcessionByStudentIdLoading: false,
        postFeeConcessionStatus: 'success',
        feeConcessions: action.data ? action.data : []
      })
    }
    case GET_FEE_CONCESSION_BY_STUDENT_ID_START:
      return Object.assign({}, state, {
        isConcessionByStudentIdLoading: true,
        postFeeConcessionStatus: ''
      })
    case GET_FEE_CONCESSION_BY_STUDENT_ID_FAIL:
      return Object.assign({}, state, {
        isConcessionByStudentIdLoading: false,
        postFeeConcessionStatus: 'fail'
      })

    case GET_ALL_STUDENTS_FEE_CONCESSION:
      console.log(action.data)
      return Object.assign({}, state, {
        isConcessionByAllStudentsLoading: false,
        postAllStudentFeeConcessionStatus: 'success',
        feeConcessions: action.data ? action.data : []
      })

    case GET_ALL_STUDENTS_FEE_CONCESSION_START:
      return Object.assign({}, state, {
        isConcessionByAllStudentsLoading: true,
        postAllStudentFeeConcessionStatus: ''
      })

    case GET_ALL_STUDENTS_FEE_CONCESSION_FAIL:
      return Object.assign({}, state, {
        isConcessionByAllStudentsLoading: false,
        postAllStudentFeeConcessionStatus: 'fail'
      })

    case PATCH_FEE_STRUCTURE: {
      return Object.assign({}, state, {
        isPatchLoading: false,
        error: false
      })
    }
    case PATCH_FEE_STRUCTURE_START:
      return Object.assign({}, state, {
        isPatchLoading: true,
        error: false
      })
    case PATCH_FEE_STRUCTURE_FAIL:
      return Object.assign({}, state, {
        isPatchLoading: false,
        error: true
      })
    case GET_FEE_ARREARS_START:
      return Object.assign({}, state, {
        isFeeArrearsLoading: true
      })
    case GET_FEE_ARREARS:
      return Object.assign({}, state, {
        feeArrears: action.data,
        isFeeArrearsLoading: false,
        error: false
      })
    case GET_FEE_ARREARS_FAIL:
      return Object.assign({}, state, {
        isFeeArrearsLoading: false,
        error: true
      })
    case POST_FEE_RECEIPT_NUMBER_START:
      return Object.assign({}, state, {
        isLoadingPostReceiptNumber: true,
        error: false
      })
    case POST_FEE_RECEIPT_NUMBER:
      return Object.assign({}, state, {
        isLoadingPostReceiptNumber: false,
        error: false
      })
    case POST_FEE_RECEIPT_NUMBER_FAIL:
      return Object.assign({}, state, {
        isLoadingPostReceiptNumber: false,
        error: true
      })
    case GET_STUDENT_WAIVE_START:
      return Object.assign({}, state, {
        isLoadingFeeWaive: true,
        error: false
      })
    case GET_STUDENT_WAIVE_SUCCESS:
      return Object.assign({}, state, {
        isLoadingFeeWaive: false,
        error: false,
        feeWaive: action.data ? action.data : []
      })
    case GET_STUDENT_WAIVE_FAIL:
      return Object.assign({}, state, {
        isLoadingFeeWaive: false,
        error: true
      })
    case UPDATE_BULK_FEE_RECEIPT_START:
      return Object.assign({}, state, {
        isBulkFeeReceiptLoading: true,
        error: false
      })
    case UPDATE_BULK_FEE_RECEIPT_SUCCESS:
      return Object.assign({}, state, {
        isBulkFeeReceiptLoading: false,
        error: false
      })
    case UPDATE_BULK_FEE_RECEIPT_FAIL:
      return Object.assign({}, state, {
        isBulkFeeReceiptLoading: false,
        error: true
      })
    case CHANGE_CONCESSION_STATUS_START:
      return Object.assign({}, state, {
        concessionStatusLoading: true,
        error: false
      })
    case CHANGE_CONCESSION_STATUS_SUCCESS:
      return Object.assign({}, state, {
        concessionStatusLoading: false,
        error: false
      })
    case CHANGE_CONCESSION_STATUS_FAIL:
      return Object.assign({}, state, {
        concessionStatusLoading: false,
        error: true
      })
    case GET_FEE_RECEIPT_NUMBER_TYPE_START:
      return Object.assign({}, state, {
        isReceiptNumberLoading: true,
        error: false
      })
    case GET_FEE_RECEIPT_NUMBER_TYPE_SUCCESS:
      return Object.assign({}, state, {
        isReceiptNumberLoading: false,
        error: false,
        feeReceiptNumberType: action.data
      })
    case GET_FEE_RECEIPT_NUMBER_TYPE_FAIL:
      return Object.assign({}, state, {
        isReceiptNumberLoading: false,
        error: true
      })
    case GET_DELETED_FEES_START:
      return Object.assign({}, state, {
        deletedFeesLoading: true,
        error: false
      })
    case GET_DELETED_FEES_SUCCESS:
      return Object.assign({}, state, {
        deletedFeesLoading: false,
        error: false,
        deletedFees: action.data
      })
    case GET_DELETED_FEES_FAILED:
      return Object.assign({}, state, {
        deletedFeesLoading: false,
        error: true
      })
    case GET_MONTHWISE_FEE_REPORT_START:
      return Object.assign({}, state, {
        isMonthWiseLoading: true,
        isMonthWiseError: false
      })
    case GET_MONTHWISE_FEE_REPORT:
      return Object.assign({}, state, {
        isMonthWiseLoading: false,
        isMonthWiseError: false,
        monthWiseReport: action.data
      })
    case GET_MONTHWISE_FEE_REPORT_FAILED:
      return Object.assign({}, state, {
        isMonthWiseLoading: false,
        isMonthWiseError: true
      })
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

// selectors
export const getById = state => state.fees.byId
export const allIds = state => state.fees.allIds
export const getisLoadingFeeReceiptReports = state =>
  state.fees.isLoadingFeeReceiptReports
export const getFeeReceiptReports = state => state.fees.feeReceiptReports
export const getFeeReceiptReportsByStudentId = state =>
  _.groupBy(state.fees.feeReceiptReports, x => x.studentId)
export const getError = state => state.fees.error
export const getisLoadingGetFeeOrder = state => state.fees.isLoadingGetFeeOrder
export const getFeeOrdersStatus = state => state.fees.getFeeOrder
export const getFeeOrders = state => state.fees.feeOrders
export const getIsLoadingGetAllClassFeeReport = state =>
  state.fees.isLoadingGetAllClassFeeReport
export const getAllClassFeeReportStatus = state =>
  state.fees.allClassFeeReportStatus
export const getAllClassFeeReport = state => state.fees.allClassFeeReport
export const getIsLoadingPostFeeConcession = state =>
  state.fees.isLoadingPostFeeConcession
export const getPostFeeConcessionStatus = state =>
  state.fees.postFeeConcessionStatus
export const getPostAllStudentsFeeConcession = state =>
  state.fees.postAllStudentFeeConcessionStatus
export const getFeeConcessions = state => state.fees.feeConcessions
export const getFeeStructurePostLoading = state => state.fees.isLoadingPost
export const getFeeConcessionByStudentIdLoading = state =>
  state.fees.isConcessionByStudentIdLoading
export const getAllStudentsFeeConcessionLoading = state =>
  state.fees.isConcessionByAllStudentsLoading
export const getIsPatchLoading = state => state.fees.isPatchLoading
export const getFeeArrears = state => state.fees.feeArrears
export const getIsLoadingFeeArrears = state => state.fees.isFeeArrearsLoading
export const getIsLoadingPostReceiptNumber = state =>
  state.fees.isLoadingPostReceiptNumber
export const getFeeWaive = state => state.fees.feeWaive
export const getIsLoadingFeeWaive = state => state.fees.isLoadingFeeWaive
export const getBulkFeeReceiptLoading = state =>
  state.fees.isBulkFeeReceiptLoading
export const getConcessionStatusLoading = state =>
  state.fees.concessionStatusLoading
export const getIsReceiptNumberLoading = state =>
  state.fees.isReceiptNumberLoading
export const getFeeReceiptNumberType = state => state.fees.feeReceiptNumberType

export const getInstitutionFeesById = createSelector(getById, byId => {
  let obj = {}
  let unArchivedData = Object.keys(byId)?.filter(id => !byId[id]?.archive)
  unArchivedData && unArchivedData?.length > 0
    ? unArchivedData?.forEach(id => {
        obj[id] = byId[id]
      })
    : (obj = {})
  return obj
  // return sessionAcademicYear
  //   ? sessionAcademicYear
  //   : institutionArr.current_academic_year
})
export const getDeletedFeesLoading = state => state.fees.deletedFeesLoading
export const getDeletedFees = state => state.fees.deletedFees

export const getMonthWiseLoading = state => state.fees.isMonthWiseLoading
export const getMonthWiseError = state => state.fees.isMonthWiseError
export const getMonthWiseReport = state => state.fees.monthWiseReport
