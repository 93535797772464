import React, { lazy, Suspense } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

//components
import ErrorBoundary from '../../../common/layout/errorBoundary'
import { PrivateLayout } from '../../../common/layout/layoutComponent'
import CommonRoute from '../common'

//catalog
const AddEditLibraryCatalog = lazy(() =>
  import('../../../library/catalog/addEdit')
)
const CatalogList = lazy(() => import('../../../library/catalog/catalogList'))
const CatalogInfo = lazy(() => import('../../../library/catalog/catalogInfo'))

//catalog policy
const AddCatalogPolicy = lazy(() =>
  import('../../../library/catalogPolicy/addPolicy')
)
const CatalogPolicyList = lazy(() =>
  import('../../../library/catalogPolicy/policyList')
)

//library users
const LibraryUserSearch = lazy(() =>
  import('../../../library/users/userSearch')
)
const LibraryUserProfile = lazy(() => import('../../../library/users/userInfo'))
const LibraryUserLendHistory = lazy(() =>
  import('../../../library/users/lend/history')
)
const LibraryUserChallanHistory = lazy(() =>
  import('../../../library/users/challanHistory')
)
const LibraryUserLendingDetails = lazy(() =>
  import('../../../library/lendingDetails/index')
)

//lend catalog
const LendCatalog = lazy(() => import('../../../library/users/lend/lendToUser'))

//library user policy
const AddLibraryUserPolicy = lazy(() =>
  import('../../../library/userPolicy/addUserPolicy')
)
const LibraryUserPolicyList = lazy(() =>
  import('../../../library/userPolicy/userPolicyList')
)
const LibraryUserPolicyToStudent = lazy(() =>
  import('../../../library/userPolicy/assignToStudent')
)

//challan
const ChallanList = lazy(() => import('../../../library/challan'))
const LendingList = lazy(() => import('../../../library/lendings'))
const ReturnedList = lazy(() => import('../../../library/returned'))

//Assign user policy to teachers
const TeacherUserPolicy = lazy(() =>
  import('../../../library/teachersUserPolicy')
)

const LibraryRoute = () => {
  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <PrivateLayout
              exact
              path="/library/catalog/add"
              name="add library catalog"
              component={AddEditLibraryCatalog}
            />
            <PrivateLayout
              exact
              path="/library/catalog/edit/:id"
              name="edit library catalog"
              component={AddEditLibraryCatalog}
            />
            <PrivateLayout
              exact
              path="/library/catalog/search"
              name="library catalog list"
              component={CatalogList}
            />
            <PrivateLayout
              exact
              path="/library/catalog/info/:id"
              name="library catalog info"
              component={CatalogInfo}
            />
            <PrivateLayout
              exact
              path="/library/catalog-policy/add"
              name="add library catalog policy"
              component={AddCatalogPolicy}
            />
            <PrivateLayout
              exact
              path="/library/catalog-policy/list"
              name="library catalog policy list"
              component={CatalogPolicyList}
            />
            <PrivateLayout
              exact
              path="/library/users/search"
              name="library users search"
              component={LibraryUserSearch}
            />
            <PrivateLayout
              exact
              path="/library/user/info/:id"
              name="library user info"
              component={LibraryUserProfile}
            />
            <PrivateLayout
              exact
              path="/library/user/lend/history"
              name="library user lend history"
              component={LibraryUserLendHistory}
            />
            <PrivateLayout
              exact
              path="/library/user/lend/:id"
              name="library user lend history"
              component={LendCatalog}
            />
            <PrivateLayout
              exact
              path="/library/user/challan/history"
              name="library user lend history"
              component={LibraryUserChallanHistory}
            />
            <PrivateLayout
              exact
              path="/library/user-policy/add"
              name="add library user policy"
              component={AddLibraryUserPolicy}
            />
            <PrivateLayout
              exact
              path="/library/user-policy/list"
              name="library user policy list"
              component={LibraryUserPolicyList}
            />
            <PrivateLayout
              exact
              path="/library/challan"
              name="challan list by status"
              component={ChallanList}
            />
            <PrivateLayout
              exact
              path="/library/catalog/lendings"
              name="Lending list by status"
              component={LendingList}
            />
            <PrivateLayout
              exact
              path="/library/catalog/returned/report"
              name="Returned list"
              component={ReturnedList}
            />
            <PrivateLayout
              exact
              path="/library/user-policy/assign-staffs"
              name="assign user-policy to teachers"
              component={TeacherUserPolicy}
            />
            <PrivateLayout
              exact
              path="/library/user-policy/assign-students"
              name="library user policy list"
              component={LibraryUserPolicyToStudent}
            />
            <PrivateLayout
              exact
              path="/library/catalog/lendings/report"
              name="library lending details"
              component={LibraryUserLendingDetails}
            />
            <CommonRoute />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </Router>
  )
}

export default LibraryRoute
